import React, { Component } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import classNames from 'classnames'
import Swiper from 'react-id-swiper'
import { Helmet } from 'react-helmet'
import 'swiper/css/swiper.css'

import siteLogo from '../images/billsby-logo.svg'
import customerLogo from '../images/velocity@2x.png'
import customerImage from '../images/coffee@2x.png'
import ScheduleADemoButton from '../components/schedule-a-demo-button'
import SignedUpValue from '../components/signed-up-value'
// import Img from "../components/image-transform"

import BadgeContent from "../components/badge-component"

const badgeData = {
  section: "header",
  link: true,
  FallBadges: [
    {
      img: require("../images/user-most-likely-to-recommend-fall.svg"),
      altText: 'user-most-likely-to-recommend-fall',
    },
  ],
}

const customerMenu = [
  {
    cusLogo: customerLogo,
    alttextLogo: 'Logo',
    cusImage: customerImage,
    alttextBanner: 'Logo',
    cusText: 'Building a subscription coffee business overnight',
  },
  {
    cusLogo: customerLogo,
    alttextLogo: 'Logo',
    cusImage: customerImage,
    alttextBanner: 'Logo',
    cusText: 'Improving enterprise customer experience',
  },
  {
    cusLogo:  customerLogo,
    alttextLogo: 'Logo',
    cusImage:  customerImage,
    alttextBanner: 'Logo',
    cusText: 'Increasing customer retention by 72%',
  },
  {
    cusLogo: customerLogo,
    alttextLogo: 'Logo',
    cusImage: customerImage,
    alttextBanner: 'Logo',
    cusText: 'Reducing call volume by 39%',
  },
  {
    cusLogo: customerLogo,
    alttextLogo: 'Logo',
    cusImage: customerImage,
    alttextBanner: 'Logo',
    cusText: 'Adding recurring revenue to a legacy business',
  },
  {
    cusLogo: customerLogo,
    alttextLogo: 'Logo',
    cusImage: customerImage,
    alttextBanner: 'Logo',
    cusText: 'Building a subscription coffee business overnight',
  },
]

class Header extends Component {
  state = {
    isActive: false,
    productClick: false,
    solutionClick: false,
    customerClick: false,
    resourceClick: false,
    hoverable: false,
    // windows: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      windows: '',
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  toggleProductDropdown = () => {
    this.setState(state => ({
      productClick: !state.productClick,
      solutionClick: false,
      customerClick: false,
      resourceClick: false,
    }))
  }

  toggleSolutionDropdown = () => {
    this.setState(state => ({
      productClick: false,
      solutionClick: !state.solutionClick,
      customerClick: false,
      resourceClick: false,
    }))
  }

  toggleCustomerDropdown = () => {
    this.setState(state => ({
      productClick: false,
      solutionClick: false,
      customerClick: !state.customerClick,
      resourceClick: false,
    }))
  }

  toggleResourcesDropdown = () => {
    this.setState(state => ({
      productClick: false,
      solutionClick: false,
      customerClick: false,
      resourceClick: !state.resourceClick,
    }))
  }

  backSubmenu = () => {
    this.setState(state => ({
      productClick: false,
      solutionClick: false,
      customerClick: false,
      resourceClick: false,
    }))
  }

  hideSubMenu =() => {
    this.setState({
      productClick: false,
      solutionClick: false,
      customerClick: false,
      resourceClick: false,
    })
  }

  componentDidMount() {
    if(window !== 'undefined') {
      this.setState({windows: window})
    }
    window.addEventListener("resize", this.updateDimensions);

    const headerOnScroll = () => {
      document.getElementsByTagName('body')[0].onscroll = (e) => {
        if(window.scrollY <= 15) {
          document.getElementsByTagName('header')[0].classList.remove("header-onscroll");
        } else {
          document.getElementsByTagName('header')[0].classList.add("header-onscroll");
        }
      };
    }

    headerOnScroll();
  }

  updateDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  getSignUpUrl() {
    return this.props.signUpUrlAlt ? this.props.signUpUrlAlt : "https://app.billsby.com/registration";
  }

  render() {
    const isOpen = this.state.isActive;
    const windows = this.state.windows

    const BannerSwiper = {
      spaceBetween: 24,
      slidesPerView: 5,
      shouldSwiperUpdate: true,
      rebuildOnUpdate: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1200: {
          slidesPerView: 5,
        },
        1199: {
          slidesPerView: 4,
        },
        991: {
          slidesPerView: 4,
        },
        640: {
          slidesPerView: 3,
        }
      }
    }


    return (
      <header 
        id="header"
        onMouseLeave={ windows.innerWidth <= 767 ? null : this.hideSubMenu} 
        // className={ `header ${this.props.isHeaderTransparent ? 'header-transparent' : ''} ${this.props.isLandingPage ? 'landing-page' : ''}`}
        className={classNames( 
          {"header-menu-open" : isOpen },
          {"header-transparent" : this.props.isHeaderTransparent },
          {"landing-page" : this.props.isLandingPage },
          {"schedule-demo" : this.props.demoSchedule },
          {"header-sign-up" : this.props.signUp },
          {"header-powered" : this.props.isPoweredPage },
          {"credit-repair-page" : this.props.isCreditRepairPage },
        )}
      >
        <Helmet>
          <script type="text/javascript">
            {`
              { 
                (function (a, d, o, r, i, c, u, p, w, m) { console.log('popup trigger'); m = d.getElementsByTagName(o)[0], a[c] = a[c] || {}, a[c].trigger = a[c].trigger || function () { (a[c].trigger.arg = a[c].trigger.arg || []).push(arguments) }, a[c].on = a[c].on || function () { (a[c].on.arg = a[c].on.arg || []).push(arguments) }, a[c].off = a[c].off || function () { (a[c].off.arg = a[c].off.arg || []).push(arguments) }, w = d.createElement(o), w.id = i, w.src = r, w.async = 1, w.setAttribute(p, u), m.parentNode.insertBefore(w, m), w = null} )(window, document, "script", "https://85013189.adoric-om.com/adoric.js", "Adoric_Script", "adoric","165b968d694b864b9b134fe7cce4f1cf", "data-key"); 
              }
            `}
          </script>
          <script>
              {
                `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N9TTN8F');`
              }
          </script>
        </Helmet>

        <div className="container container-flex">
          <Link to="/">
            <img className="logo-orig" src={siteLogo} alt="Logo" />
            {/* <Img filename={siteLogo} className="logo-orig" alt="Logo"/> */}
          </Link>
    
          <button 
            className={classNames('toggle-menu burger-close', 
              {"burger-open":isOpen},
              {'is-close': this.state.productClick},
              {'is-close': this.state.solutionClick},
              {'is-close': this.state.customerClick},
              {'is-close': this.state.resourceClick},
            )}
            onClick={() => this.setState({isActive: !isOpen})}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <button 
            className={classNames('btn-back', 
              {'is-open' : this.state.productClick},
              {'is-open': this.state.solutionClick},
              {'is-open': this.state.customerClick},
              {'is-open': this.state.resourceClick},
            )}
            onClick={this.state.hoverable ? null : this.backSubmenu}>
          </button>
    
          <div className={ isOpen ? "menu-holder menu-open" : "menu-holder menu-close"}>
            <div className="menu-link-holder">
              <ul>
                <li className="menu-link-holder-item" onMouseLeave={ windows.innerWidth <= 767 ? null : this.hideSubMenu} >
                  <div 
                    onMouseEnter={windows.innerWidth >= 768 ? (this.state.hoverable ? null : this.toggleProductDropdown) : null }
                    onClick={windows.innerWidth <= 767 ? (this.state.hoverable ? null : this.toggleProductDropdown) : null }
                    className={classNames('menu-link', 
                      {'is-click': this.state.productClick}
                    )}>Product</div>
                  <div 
                    className={classNames('submenu-holder product-holder ', {
                      'show-dropdown': this.state.productClick
                    })}
                  >
                    <div className="submenu-container product-menu-container">
                      <div className="link-wrapper product-link-wrapper">
                        <div className="link-items">
                          <p className="link-heading">Create your products</p>
                          <ul>
                            <li><Link className="sub-link" to="/product/creating/products-plans-cycles">Products, plans and cycles</Link></li>
                            <li><Link className="sub-link" to="/product/creating/trials-setup-contracts">Advanced billing options</Link></li>
                            <li><Link className="sub-link" to="/product/creating/addons-allowances">Add-ons and allowances</Link></li>
                            <li><Link className="sub-link" to="/product/creating/coupon-discounts">Coupon codes and discounts</Link></li>
                            <li><Link className="sub-link" to="/product/creating/brand-identity">Customize your brand identity</Link></li>
                          </ul>
                        </div>
                        <div className="link-items">
                          <p className="link-heading">Get and keep subscribers</p>
                          <ul>
                            <li><Link className="sub-link" to="/product/subscribers/checkout-and-payments">Checkout and payments</Link></li>
                            <li><Link className="sub-link" to="/product/subscribers/sales-tax-and-compliance">Sales tax and compliance</Link></li>
                            <li><Link className="sub-link" to="/product/subscribers/invoices-creditnotes-emails">Invoices and emails</Link></li>
                            <li><Link className="sub-link" to="/product/subscribers/account-management">In-life account management</Link></li>
                            <li><Link className="sub-link" to="/product/subscribers/dunning-retention">Dunning and retention</Link></li>
                          </ul>
                        </div>
                        <div className="link-items">
                          <p className="link-heading">Understand your business</p>
                          <ul>
                            {/* <li><Link className="sub-link" to="/">Billsby Briefing</Link></li> */}
                            <li><Link className="sub-link" to="/product/reporting/billsby-value-score">Billsby Value Score</Link></li>
                            <li><Link className="sub-link" to="/product/reporting/dashboard">Dashboard</Link></li>
                            <li><Link className="sub-link" to="/product/reporting/reports-and-insight">Reports and insights</Link></li>
                          </ul>
                        </div>
                        <div className="link-items">
                          <p className="link-heading">Works with your other tools</p>
                          <ul>
                            <li><Link className="sub-link" to="/product/integrations/accounting">Accounting</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/api-and-web-hooks">API and web hooks</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/customer-service">Customer service</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/feature-tags">Feature Tags</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/planactions">Plan actions</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/wordpress">WordPress</Link></li>
                            <li><Link className="sub-link" to="/product/integrations/zapier">Zapier</Link></li>
                          </ul>
                        </div>
                        <div className="pro-holder">
                          <div className="logo-and-button">
                            <div className="logo-holder">
                              <img className="logo-orig" src={require("../images/billsby-logo.svg")} alt="Logo" />
                              <img className="pro-img" src={require("../images/pro.png")} alt="Logo" />
                            </div>
                            <div className="button-holder">
                            <Link to="/pro/development" className="btn-orange btn-development">Development services</Link>
                            </div>
                          </div>

                          <div className="link-wrapper">
                            <div className="link-items">
                              <ul>
                                <li><Link className="sub-link" to="/pro/advanced-gateways">Advanced gateway selection</Link></li>
                                <li><Link className="sub-link" to="/pro/advanced-value-score">Advanced Value Score</Link></li>
                                <li><Link className="sub-link" to="/pro/affiliate-scheme">Affiliate schemes</Link></li>
                              </ul>
                            </div>
                            <div className="link-items">
                              <ul>
                                <li><Link className="sub-link" to="/pro/cardless-trials">Cardless trial</Link></li>
                                <li><Link className="sub-link" to="/pro/debt-collection">Debt collection</Link></li>
                                <li><Link className="sub-link" to="/pro/membership-cards">Membership cards</Link></li>
                              </ul>
                            </div>
                            <div className="link-items">
                              <ul>
                                <li><Link className="sub-link" to="/pro/priority-support">Priority support</Link></li>
                                <li><Link className="sub-link" to="/pro/quotes-bespoke-plans">Quotes and bespoke plans</Link></li>
                                <li><Link className="sub-link" to="/pro/referral-program">Referral programs</Link></li>
                              </ul>
                            </div>
                            <div className="link-items">
                              <ul>
                                <li><Link className="sub-link" to="/pro/revenue-recognition">Revenue recognition</Link></li>
                                <li><Link className="sub-link" to="/pro/whitelabel">White label</Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="block-link-wrapper">
                        <Link to="/product/payment-gateways" className="block-link-items feature-payment">
                          <p className="text-black">Payment gateways</p>
                        </Link>
                        <Link to="/product/developers" className="block-link-items feature-developer">
                          <p className="text-black">Billsby for Developers</p>
                        </Link>
                        <Link to="/product/support/"  className="block-link-items feature-support">
                          <p className="text-black">Obsessive Support</p>
                        </Link>
                        <Link className="btn-blue" to="/features">Explore all features</Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="menu-link-holder-item" onMouseLeave={ windows.innerWidth <= 767 ? null : this.hideSubMenu} >
                  <div 
                  onMouseEnter={windows.innerWidth >= 768 ? (this.state.hoverable ? null : this.toggleSolutionDropdown) : null }
                  onClick={windows.innerWidth <= 767 ? (this.state.hoverable ? null : this.toggleSolutionDropdown) : null }
                    className={classNames('menu-link', {
                      'is-click': this.state.solutionClick
                    })}>Solutions</div>
                  <div 
                    className={classNames('submenu-holder solution-holder ', {
                      'show-dropdown': this.state.solutionClick
                    })}
                  >
                    <div className="submenu-container">
                      <div className="block-link-wrapper">
                        <Link to="/solutions/fitness-and-wellbeing" className="block-link-items fitness-holder">
                          <p className="text-black">Fitness and wellbeing</p>
                        </Link>

                        <Link to="/solutions/governments" className="block-link-items gov-holder">
                          <p className="text-black">Governments and municipalities</p>
                        </Link>

                        <Link to="/solutions/healthcare-and-education" className="block-link-items healthcare-holder">
                          <p className="text-black">Healthcare and education</p>
                        </Link>

                        <Link to="/solutions/internet-of-things" className="block-link-items internet-holder">
                          <p className="text-black">Internet of things</p>
                        </Link>

                        <Link to="/solutions/media-and-entertainment" className="block-link-items media-holder">
                          <p className="text-black">Media and entertainment</p>
                        </Link>

                        <Link to="/solutions/memberships-and-clubs" className="block-link-items member-holder">
                          <p className="text-black">Membership and clubs</p>
                        </Link>

                        <Link to="/solutions/property-and-utilities" className="block-link-items property-holder">
                          <p className="text-black">Property and utilities</p>
                        </Link>

                        <Link to="/solutions/software-as-a-service" className="block-link-items software-holder">
                          <p className="text-black">Software as a service</p>
                        </Link>

                        <Link to="/solutions/travel" className="block-link-items travel-holder">
                          <p className="text-black">Travel and leisure</p>
                        </Link> 
                      </div>
                    </div>
                  </div>
                </li>
                <li className="menu-link-holder-item" onMouseLeave={ windows.innerWidth <= 767 ? null : this.hideSubMenu}>
                  <div 
                  onMouseEnter={windows.innerWidth >= 768 ? (this.state.hoverable ? null : this.toggleCustomerDropdown) : null }
                  onClick={windows.innerWidth <= 767 ? (this.state.hoverable ? null : this.toggleCustomerDropdown) : null }
                    className={classNames('menu-link', {
                      'is-click': this.state.customerClick
                    })}>Customers</div>

                    <div
                      className={classNames('submenu-holder customer-holder', {
                        'show-dropdown': this.state.customerClick
                      })}
                    >
                      <div className="submenu-container">
                        <p className="link-heading add-badge">Helping <SignedUpValue /> companies worldwide to manage their subscription billing, including...
                          <Link to="/compared" className="header-badge">
                            <img src={require("../images/user-most-likely-to-recommend-fall.svg")} alt="badge" />
                          </Link>
                        </p>

                        {/* <Swiper {...BannerSwiper}>
                          {
                            customerMenu.map((customerSwiperItem , i) => (
                              <div className="swiper-slide customer-link-holder" key={i}>
                                <Link to="/">
                                  <div className="customer-logo-holder">
                                    <img alt={customerSwiperItem.alttextLogo} className="img-logo" src={customerSwiperItem.cusLogo} />
                                  </div>
                                  <div className="content-wrapper">
                                    <div className="content-img">
                                      <img alt={customerSwiperItem.alttextBanner} className="img-banner" src={customerSwiperItem.cusImage} />
                                    </div>
                                    <p className="sub-link">{customerSwiperItem.cusText}</p>
                                  </div>
                                </Link>
                              </div>
                            ))
                          }
                        </Swiper> */}

                        <Swiper {...BannerSwiper}>
                          {
                            this.props.caseStudies.filter((caseStudy) => {
                              if ( !caseStudy.menuImage || !caseStudy.menuText || !caseStudy.companyLogo ) return false;
                              return true;
                            }).reverse().map((caseStudy) => (
                              <div className="swiper-slide customer-link-holder" key={caseStudy.slug}>
                                <Link to={`/customers/${caseStudy.slug}`}>
                                  <div className="customer-logo-holder">
                                    <img alt={caseStudy.companyName} className="img-logo" src={caseStudy.companyLogo ? caseStudy.companyLogo.url : ""} />
                                  </div>
                                  <div className="content-wrapper">
                                    <div className="content-img">
                                      <img alt={caseStudy.companyName} className="img-banner" src={caseStudy.menuImage ? caseStudy.menuImage.url : ""} />
                                    </div>

                                    <p className="sub-link">{caseStudy.menuText}</p>
                                  </div>
                                </Link>
                              </div>
                            ))
                          }
                        </Swiper>

                        <div className="customer-link-mobile">
                        {
                            this.props.caseStudies.filter((caseStudy) => {
                              if ( !caseStudy.menuImage || !caseStudy.menuText || !caseStudy.companyLogo ) return false;
                              return true;
                            }).reverse().map((caseStudy) => (
                              <div className="swiper-slide customer-link-holder" key={caseStudy.slug}>
                                <Link to={`/customers/${caseStudy.slug}`}>
                                  <div className="customer-logo-holder">
                                    <img alt={caseStudy.companyName} className="img-logo" src={caseStudy.companyLogo ? caseStudy.companyLogo.url : ""} />
                                  </div>
                                  <div className="content-wrapper">
                                    <div className="content-img">
                                      <img alt={caseStudy.companyName} className="img-banner" src={caseStudy.menuImage ? caseStudy.menuImage.url : ""} />
                                    </div>

                                    <p className="sub-link">{caseStudy.menuText}</p>
                                  </div>
                                </Link>
                              </div>
                            ))
                          }

                          <Link to="/" className="btn-blue">Load more case studies</Link>
                        </div>

                        {/* <div className="customer-link-mobile">
                          {
                            customerMenu.map((customerSwiperItem , i) => (
                              <div className="customer-link-holder" key={i}>
                                <Link to="/">
                                  <div className="customer-logo-holder">
                                    <img alt={customerSwiperItem.alttextLogo} className="img-logo" src={customerSwiperItem.cusLogo} />
                                  </div>
                                  <div className="content-wrapper">
                                    <div className="content-img">
                                      <img alt={customerSwiperItem.alttextBanner}  className="img-banner" src={customerSwiperItem.cusImage} />
                                    </div>
                                    <p className="sub-link">{customerSwiperItem.cusText}</p>
                                  </div>
                                </Link>
                              </div>
                            ))
                          }
                          <Link to="/" className="btn-blue">Load more case studies</Link>
                        </div> */}
                      </div>
                    </div>
                </li>
                <li className="menu-link-holder-item"><div className="menu-link"><Link to="/pricing">Pricing</Link></div></li>
                <li className="menu-link-holder-item"><div className="menu-link"><Link to="/compared">Compared</Link></div></li>
                <li className="menu-link-holder-item" onMouseLeave={ windows.innerWidth <= 767 ? null : this.hideSubMenu} >
                  <div 
                  onMouseEnter={windows.innerWidth >= 768 ? (this.state.hoverable ? null : this.toggleResourcesDropdown) : null }
                  onClick={windows.innerWidth <= 767 ? (this.state.hoverable ? null : this.toggleResourcesDropdown) : null }
                    className={classNames('menu-link', {
                      'is-click': this.state.resourceClick
                    })}>Resources</div>
                  <div 
                  className={classNames('submenu-holder resources-holder', {
                    'show-dropdown': this.state.resourceClick
                  })}>
                    <div className="submenu-container">
                      <div className="link-wrapper">
                        <div className="link-items">
                          <p className="link-heading">Calculators</p>
                          <ul>
                            <li><Link className="sub-link" to="/resources/tools/chargeback-calculator">Chargebacks calculator</Link></li>
                            <li><Link className="sub-link" to="/resources/tools/customer-growth-calculator">Customer growth calculator</Link></li>
                            <li><Link className="sub-link" to="/resources/tools/monthly-recurring-revenue-calculator">MRR calculator</Link></li>
                          </ul>
                        </div>
                        <div className="link-items">
                          <p className="link-heading">eBooks</p>
                          <ul>
                            <li><Link className="sub-link" to="/resources/guides/combat-chargebacks-in-your-subscription-business">Combat chargebacks</Link></li>
                            <li><Link className="sub-link" to="/resources/guides/20-ways-to-reduce-churn-and-retain-more-customers">Reduce churn</Link></li>
                            <li><Link className="sub-link" to="/resources/guides/your-essential-briefing-on-the-subscription-economy-in-2020">Subscription Economy in 2020</Link></li>
                            <li><Link className="sub-link" to="/resources/guides/delivering-great-customer-service-to-subscription-customers">Deliver great customer service</Link></li>
                          </ul>
                        </div>
                        <div className="link-items">
                          <p className="link-heading">Developers</p>
                          <ul>
                            <li><Link className="sub-link" to="/product/developers">Billsby for Developers</Link></li>
                            <li><a className="sub-link" rel="noopener noreferrer" target='_blank' href="https://support.billsby.com/specification">API specification</a></li>
                            <li><a className="sub-link" rel="noopener noreferrer" target='_blank' href="https://support.billsby.com/discuss">Community</a></li>
                            <li><a className="sub-link" rel="noopener noreferrer" target='_blank' href="https://support.billsby.com">Documentation</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="block-link-wrapper">
                        <Link to="/" className="block-link-items generation-holder">
                          <p className="text-black">Design beautiful buttons for Billsby Checkout and Self-Service Account Management</p>
                        </Link>
                        <Link className="btn-blue" to="/resources/tools/buttongenerator">Try our button generator</Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="menu-link-holder-item"><div className="menu-link"><Link to="/subscription-economy-blog/">Blog</Link></div></li>
              </ul>
            </div>
            <div className="user-link-holder">
              {this.props.headerNoButtons 
              ?
              ''
              :
              <ul>
              {
              this.props.demoSchedule 
              ? 
              <li className="schedule-a-demo">
                 <ScheduleADemoButton buttonColor="orange"/>
              </li>
              : 
              <>
              {
                this.props.signUp ?
                <React.Fragment>
                  {
                    this.props.signUpBtnConfig ?
                      this.props.signUpBtnConfig.linkIsExternal
                      ? 
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange" rel="noopener noreferrer" target='_blank'>Sign up for free</a></li>
                      :
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange">Sign up for free</a></li>
                    :
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange" rel="noopener noreferrer" target='_blank'>Sign up for free</a></li>
                  }
                </React.Fragment>
                :
                <>
                  <li className="login">
                    <a href="https://app.billsby.com/login" rel="noopener noreferrer" target='_blank' className="menu-link">Login</a>
                  </li>
                  <li>
                    <ScheduleADemoButton/>
                  </li>
                  {
                    this.props.signUpBtnConfig ?
                      this.props.signUpBtnConfig.linkIsExternal
                      ? 
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange" rel="noopener noreferrer" target='_blank'>Sign up for free</a></li>
                      :
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange">Sign up for free</a></li>
                    :
                      <li className="sign-up"><a href={this.getSignUpUrl()} className="btn-orange" rel="noopener noreferrer" target='_blank'>Sign up for free</a></li>
                  }
                </>
              }
              </>
              }
              </ul>
              }
            </div>
          </div>
        </div>
      </header>
    )
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        gcms {
          caseStudies {
            id
            slug
            companyName
            menuImage {
              url
            }
            menuText
            companyLogo {
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <Header caseStudies={data.gcms.caseStudies} {...props}/>
    )}
  />
)