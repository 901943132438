import React, { useState, useEffect } from "react"
import axios from "axios"
// import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faCalendarAlt, faCalendarCheck } from "@fortawesome/free-solid-svg-icons"
import classNames from 'classnames'
import Calendly from "../components/calendly"
// import Img from "../components/image-transform"
// import InlineWidget from "react-calendly"

const stopProp = e => {
  e.stopPropagation()
}

const ScheduleDemoModal = ({ removeOverlay }) => {
  const [name, setName] = useState("")
  const [emailAdd, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [company, setCompany] = useState("")
  const [companyDescription, setCompanyDescription] = useState("")
  const [companyDomain, setCompanyDomain] = useState("")
  const [calendlyResponse, setCalendlyResponse] = useState("")
  const [error, isError] = useState(false)

  const [showCalendly, setShowCalendly] = useState(false)

  const BRANDFETCH_API_KEY = "oIZ9PT7H129HV3PWel5w93cTdvdE1QYI63811Aa4"

  const handleChangeName = event => {
    setName(event.target.value)
  }

  const handleChangeEmail = event => {
    setEmail(event.target.value)
  }

  const handleChangePhone = event => {
    setPhoneNumber(event.target.value)
  }

  const handleChangeCompany = event => {
    setCompany(event.target.value)
  }

  const handleDataCallBack = res => {
    console.log(res)
    setShowCalendly(false)
  }

  async function doProcess() {
    try {
      let subscriber = await axios({
        method: "POST",
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/103064484/subscribers`,
        headers: {
          "content-type": "application/json",
          "X-MailerLite-ApiKey": "f13b6f484a31a41bbdefdd972b1d6099",
        },
        data: {
          id: 774554950104622600,
          email: emailAdd,
          name: name,
          type: "active",
          fields: {
            company: company,
            phone: phoneNumber,
          },
        },
      })

      var split = emailAdd.split("@")
      var domain = split[1]
      setCompanyDomain(domain)

      let compDescription = await axios({
        method: "POST",
        url:
          "https://cors-anywhere.herokuapp.com/https://api.brandfetch.io/v1/company",
        headers: {
          "x-api-key": BRANDFETCH_API_KEY,
          "content-type": "application/json",
        },
        data: {
          domain: domain,
        },
      })

      setCompanyDescription(compDescription.data.response.description)
      setShowCalendly(true)
      console.log(subscriber)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const checkLocalStorage = setInterval(output, 1000)

      function output() {
        if (typeof window !== "undefined") {
          const result = localStorage.getItem("calendly_result")
          setCalendlyResponse(result)
        }
      }
      return () => {
        clearInterval(checkLocalStorage)
      }
    }
  })

  // const checkLocalStorage = setInterval(output, 1000);

  // function output() {
  //   if (typeof window !== 'undefined') {
  //     const result = localStorage.getItem('calendly_result')
  //     setCalendlyResponse(result)
  //   }
  // }

  if (calendlyResponse === "calendly.event_scheduled") {
    axios({
      method: "DELETE",
      url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/103064484/subscribers/${emailAdd}`,
      headers: {
        "content-type": "application/json",
        "X-MailerLite-ApiKey": "f13b6f484a31a41bbdefdd972b1d6099",
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmit = () => {
    doProcess()
    // const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // const result = pattern.test(emailAdd); // this is boolean
  }

  return (
    <div className="overlay_background modal" onClick={e => removeOverlay()}>
      <div className="overlay_card" onClick={e => stopProp(e)}>
        <div className="modal-container">
          <button
            className="btn-close"
            onClick={e => {
              e.preventDefault()
              removeOverlay()
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="close-icon" />
          </button>
          {
            calendlyResponse === "calendly.event_scheduled" ?
            (
              <div className="first-col first-col-2">
                <div className="modal-text-wrapper">
                  <h2 className="modal-title">You're all set {name}! We've sent you a booking confirmation email.</h2>
                  <p className="modal-description">
                    Whilst you're waiting for your product demo, why not sign up a free trial of Billsby? There's <span className="bold">no credit card required,</span> you'll <span className="bold">get your first $5,000 of revenue with no fees</span> and it'll help you to <span className="bold">make the most out of your product demo.</span>
                  </p> 
                  <p className="modal-description">It only takes a couple of minutes to sign up.</p> 
                </div>
                <div className="modal-btn-wrapper">
                  <a href="https://app.billsby.com/registration" target="_blank" rel="noopener noreferrer" className="btn-lg btn-orange">Start your free trial of Billsby today</a>
                </div>
                <div className="modal-image-wrapper">
                  <div className="modal-image-holder">
                    {/* <Img filename="free-trial.png" className="modal-image" alt="free-trial"/> */}
                    <img src={require("../images/free-trial.svg")} className="modal-image" alt="free-trial"/>
                  </div>
                </div>
              </div>
            )
            :
            (
              <div className={classNames('first-col', 
              {'disabled' : showCalendly === true},
              )}>
                <div className="modal-text-wrapper">
                  <h2 className="modal-title">
                    Our team can't wait to show you how Billsby can help your
                    business to succeed
                  </h2>
                  <p className="modal-description">
                    We only need a few bits of information to get your demo booked
                    in for you - and you'll be able to choose whichever time suits
                    you best.
                  </p>
                </div>
                <div className="form-wrapper">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit()
                    }}
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        className={`input-field ${error ? "error" : ""}`}
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={handleChangeName}
                        required
                      />

                      <input
                        type="email"
                        className={`input-field ${error ? "error" : ""}`}
                        placeholder="Work Email Address"
                        name="emailAdd"
                        value={emailAdd}
                        onChange={handleChangeEmail}
                        required
                      />

                      <input
                        type="number"
                        className={`input-field ${error ? "error" : ""}`}
                        placeholder="Phone Number"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChangePhone}
                        required
                      />

                      <input
                        type="text"
                        className={`input-field ${error ? "error" : ""}`}
                        placeholder="Company Name"
                        name="companyName"
                        value={company}
                        onChange={handleChangeCompany}
                        required
                      />

                      <button type="submit" className={`btn-orange`}>
                        Pick a time that suits you
                      </button>
                    </div>
                  </form>
                  <p className="modal-text">
                    By booking a demo, you're saying it's OK for us to add you to
                    our mailing list, and contact you as detailed in our{" "}
                    <a href="/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>.
                  </p>
                </div>
              </div>
            )
          }

          <div className="second-col">
            {calendlyResponse === "calendly.event_scheduled" ? (
              <FontAwesomeIcon icon={faCalendarCheck} className="calendar-icon" />
            ) : showCalendly ? (
              <Calendly
                name={name}
                email={emailAdd}
                a1={phoneNumber}
                a2={company}
                a3={companyDomain}
                a4={companyDescription}
                dataCallback={handleDataCallBack}
              />
            ) : (
              <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleDemoModal
