import React from 'react'
import { Helmet } from 'react-helmet'

const Calendly = (props) => {

    return (
        <div >
           <Helmet>
                 <script type="text/javascript">
                    {
                        `Calendly.initInlineWidget({url: 'https://calendly.com/billsby/product-demo?hide_event_type_details=1&text_color=252422&primary_color=fe7f2d',
                            parentElement: document.getElementById('calendly'),
                            prefill: {
                                name: "${props.name}",
                                email: "${props.email}",
                                customAnswers: {
                                    a1: "${props.a1}",
                                    a2: "${props.a2}",
                                    a3: "${props.a3}",
                                    a4: "${props.a4}"
                                }
                            }

                        });
                        function isCalendlyEvent(e) {
                            return e.data.event &&
                                   e.data.event.indexOf('calendly') === 0;
                          };
                           
                        window.addEventListener(
                            'message',
                            function(e) {
                              if (isCalendlyEvent(e)) {
                                localStorage.setItem('calendly_result', e.data.event);
                              }
                            }
                        );`
                    }
                </script>
            </Helmet>
          <div id="calendly" className="calendly-area" style={{ height: '600px', width: '435px' }}></div>

        </div>
    )
}

export default Calendly